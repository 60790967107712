import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './RibbonLink.module.scss';

const RibbonLink = ({ boldText, regularText, preamble, link }) => {
    return (
        <div className={styles['RibbonLink']}>
            {!!link && (
                <a href={link} className={styles['RibbonLink__Link']}>
                    <div className={styles['RibbonLink__Grid']}>
                        <div className={styles['RibbonLink__TextContainer']}>
                            <div
                                className={
                                    styles['RibbonLink__HeadingAndIconWrapper']
                                }>
                                {(!!boldText || !!regularText) && (
                                    <p
                                        className={
                                            styles['RibbonLink__Heading']
                                        }>
                                        {boldText}
                                        {!!regularText && (
                                            <span
                                                className={
                                                    styles['RibbonLink__Text']
                                                }>
                                                {' '}
                                                {regularText}
                                            </span>
                                        )}
                                    </p>
                                )}
                            </div>
                            {!!preamble && (
                                <p className={styles['RibbonLink__Preamble']}>
                                    {preamble}
                                </p>
                            )}
                        </div>
                    </div>
                </a>
            )}
        </div>
    );
};

RibbonLink.propTypes = {
    boldText: PropTypes.string,
    regularText: PropTypes.string,
    preamble: PropTypes.string,
    link: PropTypes.string.isRequired,
};

RibbonLink.defaultProps = {
    boldText: '',
    regularText: '',
    preamble: '',
    link: '',
};

export default RibbonLink;
